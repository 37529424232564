import React from "react"
import {Link} from "gatsby"

import Layout from "../layout"
import ContactForm from "../contactform"

import * as styles from "../../styles/pages/index.module.css"
import imgbox from "../../images/boxholder.png"
import imgback from "../../images/back.png"

const formatTools = require("../../../lib/formatTools");

const Careers =  ({dataobj}) => {
	const curdate = formatTools.getDateStr(0);

	var jobstructure = {
		"@context" : "https://schema.org/",
		"@type" : "JobPosting",
		"title" : dataobj.jobname,
		"description" : dataobj.summary,
		"identifier": {
				"@type": "PropertyValue",
				"name": "IAMNET",
				"value": dataobj.jobid
		},
		"datePosted" : dataobj.jobpoststart + "T00:00",
		"validThrough" : dataobj.jobpostend + "T00:00",
		"employmentType" : "FULL_TIME",
		"hiringOrganization" : {
				"@type" : "Organization",
				"name" : "Integrated Access Management Networks Corporation",
				"sameAs" : "https://iamnet.com.ph",
				"logo" : "https://iamnet.com.ph/images/logo.png"
		},
		"jobLocation" : {
				"@type" : "Place",
				"address" : {
						"@type" : "PostalAddress",
						"name" : "704 Rizal Ave Ext.",
						"streetAddress" : "704 Rizal Ave Ext.",
						"addressLocality" : "Grace Park East, Caloocan City",
						"addressRegion" : "Metro Manila",
						"postalCode" : "1403",
						"addressCountry" : "PH"
				}
		},
		"applicantLocationRequirements": {
				"@type": "Country",
				"name": "Philippines"
		}			
	};
	const contactfields = [
		{
			"name": "formlastname",
			"label": "Last Name",
			"value": "",
			"required": true,
		},
		{
			"name": "formfirstname",
			"label": "First Name",
			"value": "",
			"required": true,
		},
		{
			"name": "formmiddlename",
			"label": "Middle Name",
			"value": "",
		},
		{
			"name": "formposition",
			"label": "",
			"value": dataobj.jobname
		},
		{
			"name": "formemail",
			"label": "Email",
			"type": "email",
			"value": "",
			"required": true,
		},
		{
			"name": "formmobile",
			"label": "Mobile",
			"type": "tel",
			"value": "",
			"required": true,
		},
		{
			"name": "formmsg",
			"label": "Brief Introduction",
			"value": "",
			"required": true,
		}
	];
	if (dataobj.jobsalary) {
		jobstructure.baseSalary = {
			"@type" : "MonetaryAmount",
			"currency" : "PHP",
			"value" : {
				"@type" : "QuantitativeValue",
				"value" : dataobj.jobsalary,
				"unitText" : "MONTH"
			}
		};
	}
	if (dataobj.workfromhome) {
		jobstructure.jobLocationType = "TELECOMMUTE";
	}
/*
		jobid:0002,
		jobname:"Technical Support Officer",
		jobpoststart:"2022-01-25",
		jobpostend:"2022-02-25",
		jobsalary:15000,
		summary: "Deploy and maintain company work products. Visit, train and support deployments to ensure smooth operations",
		responsibilities: [
			"Contact person for clients.  Coordinates between customers, sales and development teams",
			"Maintain and operate company's products and train others to do so",
			"Regular visitations of deployments of company products and services",
			"Maintain regular correspondence with customers and gather feedback whenever possible",
			"Represent the company, the company's products and services in a positive manner",
			"Assist in indirect sales, marketing, promotional, after-sales and related activities",
			"Assist in office administration",
			"Performs other duties training and/ or hours of work as may be reasonably required and which are consistent with the general level of responsibility of this job",
		],
		qualifications: [
			
		],
		preferred: [
			
		]

*/
	return (
		<Layout activePath={'careers/'+dataobj.url} pageDescription={dataobj.summary} pageTitle={dataobj.jobname} structuredData={jobstructure} >
			<div className={styles.mainbackholder}>
				<Link to="/careers"><img src={imgback} alt={"Back"} /><span>Careers</span></Link>
			</div>

			<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<h1 className={styles.mainbgcolor}><span>{dataobj.jobname}</span></h1>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>
			<div className={styles.mainsubsection+" "+styles.mainaltbgcolor+" text-centered"}>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<h2 className={styles.mainaltbgcolor}>Description</h2>
				<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
				<div className={styles.mainsubsectionmargin}>
					<p className={styles.mainaltbgcolor}>{dataobj.summary}</p>
				</div>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>
			{dataobj.responsibilities.length > 0 && <div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<h2 className={styles.mainbgcolor}>Primary Responsibilities</h2>
				<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
				<ul className={styles.mainlistholder+" "+styles.mainsubsectionmargin}>
					{dataobj.responsibilities.map((entry, idx) => {
						return <li>{entry}</li>;
					})
					}

				</ul>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>}
			{dataobj.project &&<>
				{dataobj.project === "negosyonow" &&
					<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
						<hr/>
						<img className={styles.mainlogomargin} src={imgbox} alt={""} />
						<h2 className={styles.mainbgcolor}>Primary Project</h2>
						<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
						<div className={styles.mainsubsectionmargin}>
							<p className={styles.mainbgcolor}>{"Negosyo Now"}</p>
							<a style={{"textDecoration": "underline"}} href="https://negosyonow.com" rel="noreferrer" target="_blank">More Information</a>
						</div>
						<img className={styles.mainlogomargin} src={imgbox} alt={""} />
					</div>
				}
			</>}

			{dataobj.qualifications.length > 0 && <div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
				<hr/>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<h2 className={styles.mainbgcolor}>Qualifications</h2>
				<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
				<ul className={styles.mainlistholder+" "+styles.mainsubsectionmargin}>
					{dataobj.qualifications.map((entry, idx) => {
						return <li>{entry}</li>;
					})
					}
				</ul>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>}
			{dataobj.preferred.length > 0 && <div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
				<hr/>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<h2 className={styles.mainbgcolor}>Preferred Candidates</h2>
				<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
				<ul className={styles.mainlistholder+" "+styles.mainsubsectionmargin}>
					{dataobj.preferred.map((entry, idx) => {
						return <li>{entry}</li>;
					})
					}
				</ul>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>}
			{curdate <= dataobj.jobpostend ?
			<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
				<hr/>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<h2 className={styles.mainbgcolor}>Message us if you're interested</h2>
				<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
				<ContactForm
						submitURL={"https://webform.api.iamnet.ph/iamnethr"}
						fields={contactfields}
						sendLabel={true}
					/>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>
			:
			<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
				<hr/>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				<p className="text-centered">No openings at the moment</p>
				<img className={styles.mainlogomargin} src={imgbox} alt={""} />
			</div>
			}
		</Layout>
	)
}

export default Careers;
