import React from "react"

import Careers from "../components/careers"

const CareersPageTemplate = ({pageContext}) => {
	return (
		<Careers dataobj={pageContext.data}/>
	)
}

export default CareersPageTemplate